
/**
 * Service for handling authentication-related functionality.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { CustomerService } from '@core/customer/customer.service';
import { Customer } from '@core/customer/customer.types';
import { LocalStorageService } from '@core/utils/localStorage/localStorage.service';
import { UserService } from '@core/user/user.service';
import { User } from '@core/user/user.types';
import { environment } from '@env/environment';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import {
  applyActionCode, browserSessionPersistence, confirmPasswordReset, createUserWithEmailAndPassword, FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider, sendEmailVerification, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword, signInWithPopup, signOut, TwitterAuthProvider, User as FirebaseUser, UserCredential
} from 'firebase/auth';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, Observable, of } from 'rxjs';
import { AuthUtils } from './auth.utils';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { SessionService } from '@core/session/session.service';


/**
 * Service that provides authentication functionality.
 */
@Injectable({providedIn: 'root'})
/**
 * The AuthService class provides authentication-related functionality for the application.
 */
export class AuthService {

  /**
   * Indicates whether the user is authenticated or not.
   */
  authenticated: boolean = false;

  /**
   * The list of supported languages in the application.
   */
  private _languageList: { code: string; label: string }[] = [];

  /**
   * The list of supported locales in the application.
   */
  private _localeList: { code: string; locale: string }[] = [];
  
  /**
   * Constructor
   * @param _environmentService The environment service.
   * @param _customerService The customer service.
   * @param _translocoService The translation service.
   * @param _translocoLocaleService The locale service.
   * @param _dateAdapter The date adapter.
   * @param _httpClient The HTTP client.
   * @param _userService The user service.
   * @param _cookieService The cookie service.
   * @param _sessionService The session service.
   * @param _localStorageService The local storage service.
   */
  constructor(
    private _environmentService: EnvironmentService,
    private _customerService: CustomerService,
    private _translocoService: TranslocoService,
    private _translocoLocaleService: TranslocoLocaleService,
    private _dateAdapter: DateAdapter<any>,
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _cookieService: CookieService,
    private _sessionService: SessionService,
    private _localStorageService: LocalStorageService
  ) {
    this._languageList = [
      { code: 'en', label: 'English' },
      { code: 'de', label: 'Deutsch' },
      { code: 'fr', label: 'Français' }
    ];
    this._localeList = [
      { code: 'en', locale: 'en-US' },
      { code: 'de', locale: 'de-DE' },
      { code: 'fr', locale: 'fr-FR' }
    ];
    // Get around the circular dependency issue
    this._userService.setAuthService(this);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns the access token from local storage.
   * @returns {string} The access token.
   */
  get accessToken(): string {
    return this._localStorageService.getItem('accessToken') ?? '';
  }

  /**
   * Sets the access token in local storage.
   * @param token - The access token to be stored.
   */
  set accessToken(token: string) {
    this._localStorageService.setItem('accessToken', token);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sets the site language and updates the locale and date adapter accordingly.
   * @param language - The language code to set.
   * @returns void
   */
  setSiteLanguage(language: string): void {
    const sitelanguage = this._languageList.some(o => o.code === language) ? language : 'fr';
    const userLocale: string | undefined = this._localeList.find(o => o.code === sitelanguage)?.locale;
    if (!userLocale) {
      return;
    }
    this._translocoService.setActiveLang(sitelanguage);
    this._translocoLocaleService.setLocale(userLocale);
    this._dateAdapter.setLocale(userLocale);
    this._localStorageService.setItem('lang', sitelanguage);
    if (window['dataLayer']) {
      window['dataLayer'].push({ 
          language: sitelanguage
      });
    }
  }

  /**
   * Sign in with Facebook provider.
   * @returns A Promise that resolves with a User object.
   */
  async signInWithFacebook(): Promise<User> {
    const provider = new FacebookAuthProvider();
    return this.signInWithProvider(provider);
  }

  /**
   * Signs in the user with Twitter authentication provider.
   * @returns A Promise that resolves with the authenticated user.
   */
  async signInWithTwitter(): Promise<User> {
    const provider = new TwitterAuthProvider();
    return this.signInWithProvider(provider);
  }

  /**
   * Sign in with Google authentication provider.
   * @returns A Promise that resolves with the authenticated user.
   */
  async signInWithGoogle(): Promise<User> {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.signInWithProvider(provider);
  }

  /**
   * Sign in the user with Apple OAuth provider.
   * @returns A Promise that resolves with the authenticated user.
   */
  async signInWithApple(): Promise<User> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('name');
    provider.addScope('email');
    provider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: 'fr'
    });
    return this.signInWithProvider(provider);
  }

  /**
   * Signs in a user with email and password.
   * @param credentials - An object containing email and password.
   * @returns A Promise that resolves with a User object upon successful sign-in.
   * @throws An error if the user is already authenticated, if sign-in fails, or if user is not found.
   * @remarks Firebase Auth web sessions are single host origin and will be persisted for a single domain only.
   */
  async signInWithEmail(credentials: {
    email: string;
    password: string;
  }): Promise<User> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      throw new Error('User is already logged in');
    }
    try {
      /*
          Indicates that the state will be persisted even when the browser window is closed or the activity
          is destroyed in React Native. An explicit sign out is needed to clear that state. Note that Firebase
          Auth web sessions are single host origin and will be persisted for a single domain only.
      */
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      const userCredential: UserCredential =
        await signInWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
        );
      if (!userCredential) {
        throw new Error('User not found');
      }
      const qartUser: User = await this.completeSignIn(userCredential.user, 'email');
      return qartUser;
    }
    catch (error) {
      this.signOut();
      console.log(error);
      /*
      if (error.message == 'Email not verified') {
        this.doLogout()
          .then(() => reject({
            status: -1,
            code: 'auth/email-not-verified',
            message: 'You must verify your email before you can log in.'
          }));
      }
      */
      throw new Error('Unable to sign in');
    }
  }

  /**
   * Sign in a user with a given provider.
   * @param provider - The provider to sign in with.
   * @returns A Promise that resolves with the signed in user.
   * @throws An error if the user is already logged in, if the user is not found, or if there is an issue signing in.
   */
  async signInWithProvider(provider: any): Promise<User> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      throw new Error('User is already logged in');
    }
    try {
      /*
          Indicates that the state will be persisted even when the browser window is closed or the activity
          is destroyed in React Native. An explicit sign out is needed to clear that state. Note that Firebase
          Auth web sessions are single host origin and will be persisted for a single domain only.
      */
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      const userCredential: UserCredential = await signInWithPopup(auth, provider);
      if (!userCredential) {
        throw new Error('User not found');
      }
      const qartUser: User = await this.completeSignIn(userCredential.user, 'social');
      return qartUser;
    } catch (error) {
      this.signOut();
      console.log(error);
      throw new Error('Unable to sign in');
    }
  }

  /**
   * Completes the sign-in process for the user and performs additional actions such as logging in with Qart database,
   * creating a customer if it doesn't exist, and setting the site language.
   * @param user The Firebase user object.
   * @param method The sign-in method used.
   * @returns The Qart user object.
   * @throws An error if the user could not be updated or created, or if the customer could not be created.
   */
  async completeSignIn(user: FirebaseUser, method: string): Promise<User> {
    // Store the user on the user service
    this._userService.user = user;
    // Retrieve the access token
    const accessToken = await user.getIdToken(true);
    // Store the access token in the local storage
    this.accessToken = accessToken;
    // Set the authenticated flag to true
    this.authenticated = true;

    /*
      Log in with the Qart database
      Firebase Auth currently persists the Auth State in web storage (localStorage/indexedDB)
      and are not transmitted along the requests. You are expected to run client side code to get the Firebase ID token
      and pass it along the request via header, or POST body, etc. On your backend, you would verify the ID token before
      serving restricted content or processing authenticated requests. This is why in its current form, CSRF is not a problem
      since Javascript is needed to get the ID token from local storage and local storage is single host origin making it
      not accessible from different origins. If you plan to save the ID token in a cookie or set your own session cookie after
      Firebase Authentication, you should then look into guarding against CSRF attacks.
    */
    // Login on Qart
    await firstValueFrom(this._httpClient
      .post(
        `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/login`,
        { idToken: accessToken },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials: true
        }
      ));
    // Retrieve the Qart User
    let qartUser: User = await this._userService.getQartUser();
    if (qartUser) {
      (window as any)['dataLayer']?.push({
        event: 'login',
        method
      });
      qartUser = await this._userService.updateUser();
      if (!qartUser) {
        throw new Error('User could not be updated');
      }
    } else {
      (window as any)['dataLayer']?.push({
        event: 'sign_up',
        method
      });
      qartUser = await this._userService.createUser();
      if (!qartUser) {
        throw new Error('User could not be created');
      }
    }
    // Create the customer if it doesn't exist
    let currentCustomer: Customer = await firstValueFrom(this._customerService.getCurrentCustomer());
    if (!currentCustomer) {
      currentCustomer = await firstValueFrom(this._customerService.addCustomer());
      if (!currentCustomer) {
        throw new Error('Unable to create the customer');
      }
    } else if (currentCustomer.stripeId === 'none') {
      currentCustomer = await firstValueFrom(this._customerService.updateCustomer(currentCustomer));
      if (!currentCustomer) {
        await this.signOut();
        throw new Error('Unable to update the customer');
      }
    }
    // Set the site language
    const cookieLanguage: string | null = this._localStorageService.getItem('lang');
    const userPreferredLanguage: string | undefined = qartUser.preferredLanguage;
    if (userPreferredLanguage && userPreferredLanguage !== this._translocoService.getActiveLang()) {
      this.setSiteLanguage(userPreferredLanguage);
    } else if (cookieLanguage && cookieLanguage !== this._translocoService.getActiveLang()) {
      this.setSiteLanguage(cookieLanguage);
    }
    /*
    if (!qartUser.emailVerified) {
      throw Error('Email not verified');
    }
    */
    return qartUser;
  }

  /**
   * Signs up a user with the provided email and password.
   * @param credential An object containing the email and password of the user.
   * @throws An error if the user is already authenticated or if sign up fails.
   * @returns A Promise that resolves with void.
   */
  async signUp(credential: {
    email: string;
    password: string;
  }): Promise<void> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      throw new Error('User is already logged in');
    }
    try {
      /*
          Indicates that the state will be persisted even when the browser window is closed or the activity
          is destroyed in React Native. An explicit sign out is needed to clear that state. Note that Firebase
          Auth web sessions are single host origin and will be persisted for a single domain only.
      */
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, credential.email, credential.password);
      if (!userCredential) {
        throw new Error('User not found');
      }
      const user: FirebaseUser = userCredential.user;
      const actionCodeSettings = {
        url: `https://${location.hostname}/redirect`
      };
      await sendEmailVerification(user, actionCodeSettings);
    } catch (error) {
      console.log(error);
      throw new Error('Unable to sign up');
    }
  }

  /**
   * Verifies the password reset code sent to the user's email.
   * @param actionCode - The password reset code sent to the user's email.
   * @returns A Promise that resolves when the password reset code is verified.
   * @throws An error if the password reset code is invalid or expired.
   */
  async verifyActionCode(actionCode: string): Promise<void> {
    try {
      const auth = getAuth();
      await applyActionCode(auth, actionCode);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Sends a password reset email to the specified email address.
   * @param email - The email address to send the password reset email to.
   * @returns A Promise that resolves when the password reset email is sent successfully, or rejects with an error if the email fails to send.
   */
  async forgotPassword(email: string): Promise<void> {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email, {
        url: `https://${location.hostname}/redirect`
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Resets the user's password with the given action code and new password.
   * @param actionCode - The action code sent to the user's email for resetting the password.
   * @param newPassword - The new password to be set for the user.
   * @returns A Promise that resolves when the password is successfully reset, or rejects with an error.
   */
  async resetPassword(actionCode: string, newPassword: string): Promise<void> {
    try {
      const auth = getAuth();
      await confirmPasswordReset(auth, actionCode, newPassword);
    } catch (error) {
      console.log(error);
    }
  }
 
  /**
   * Resends the confirmation email to the currently logged in user.
   * @returns A Promise that resolves with void when the email is successfully sent.
   * @throws An error if the user is not logged in.
   */
  async resendConfirmationEmail(): Promise<void> {
    try {
        const user: FirebaseUser = this._userService.user;
        if (!user) {
            throw new Error('User not found');
        }
        // Resend the email
        const actionCodeSettings = {
            url: `https://${location.hostname}/redirect`
        };
        return sendEmailVerification(user, actionCodeSettings);
    }
    catch (error) {
        console.log('error', error);
    }
  }

  /**
   * Signs out the user by removing the access token from local storage, keeping only the content of the basket in the session data, setting the authenticated flag to false, resetting the user service, deleting all cookies, and making a POST request to the logout endpoint.
   * @returns A promise that resolves when the user is signed out.
   */
  signOut(): Promise<any> {
    // Remove the access token from the local storage
    this._localStorageService.removeItem('accessToken');
    // Keep only the content of the basket in the session data and trash the rest
    this._sessionService.keepBasketOnly();
    // Set the authenticated flag to false
    this.authenticated = false;
    this._userService.reset();
    const auth = getAuth();
    return signOut(auth)
      .then(() => {
        this._cookieService.deleteAll();
        return firstValueFrom(this._httpClient.post(
          `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/logout`,
          null,
          {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
            withCredentials: true
          }));
      })
      .catch(error => console.log('error', error));
  }

  /**
   * Checks if the user is authenticated by verifying the access token.
   * @returns An Observable that emits a boolean value indicating whether the user is authenticated or not.
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    // I don't think this is possible with Firebase, so we return false
     
    /**
     * We return true as the access token can be used.
     * In any case, when the authentication will not work, Firebase won't be able to decode the token
     * and the user will send back a 401 error. The error will be intercepted by the interceptor and the user will be signed out.
     */
    return of(true);
  }
}
